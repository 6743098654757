import Navbar from '../components/Navbar';
function Careers() {
    return (
      <>
  <div className="container-fluid topbar px-20 d-none d-lg-block">
      <div className="container-fluid px-20">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                (+1) 832-225-8898
              </a>
              <a href="/" className="text-light me-0">
                <i className="fas fa-envelope text-primary me-2" />
                info@celersoft.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-facebook-f text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-twitter text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-instagram text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-0"
              >
                <i className="fab fa-linkedin-in text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid sticky-top px-0">
      <div
        className="position-absolute bg-dark"
        style={{ left: 0, top: 0, width: "100%", height: "100%" }}
      ></div>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
          <a href="/" className="navbar-brand p-0">
            <img src="assets/img/logofooter.png" />

          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Navbar />
            </div>
            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
            </div>
          </div>
        </nav>
      </div>
    </div>
<div
className="modal fade"
id="searchModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-fullscreen">
  <div className="modal-content rounded-0">
    <div className="modal-header">
      <h4 className="modal-title mb-0" id="exampleModalLabel">
        Search by keyword
      </h4>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body d-flex align-items-center">
      <div className="input-group w-75 mx-auto d-flex">
        <input
          type="search"
          className="form-control p-3"
          placeholder="keywords"
          aria-describedby="search-icon-1"
        />
        <span id="search-icon-1" className="input-group-text p-3">
          <i className="fa fa-search" />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
{/* Modal Search End */}
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Careers
  </h4>
 
</div>
</div>
<div className="container">
<section className="portfolio section-padding" id="portfolio">
  <div className="row">
    <div className="section-title  wow zoomIn">
      <div className="indented">
        <div className="panel panel-default">
          <div className="panel-heading">
            Software Engineer | Job ID: 20221101 | 1-Nov-2022
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Master’s degree in Computer Science, Engineering (Any),
                Management, Technology or related to design, develop, implement,
                maintain &amp; test business functions and web applications
                using a variety of languages, tools, methodologies and
                technologies.
              </li>
              <li>
                Develop, create and modify general computer applications
                software or specialized utility programs.
              </li>
              <li> Analyze user needs and develop software solutions.</li>
              <li>
                Design software or customize software for client use with the
                aim of optimizing operational efficiency.
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location: Houston, TX with required travel to client
                locations throughout the USA
              </li>
              <li className="list-group-item">Salary: $92,685/Year</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 1500 South Dairy Ashford Rd, Suite 355, Houston,
            TX- 77077
            <h4>
              Send resume to{" "}
              <a href="mailto:hr@celersoft.com">hr@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Senior Software Engineer | Job ID: 20221102 | 1-Nov-2022
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Bachelor’s degree in Engg(any),Computer Science,Technology or
                related and 5 yrs of exp to analyze, design, code, test and
                implement software solutions.
              </li>
              <li>
                Prepare and review Use cases, User Stories,Prototypes, Process
                flows and data dictionaries.
              </li>
              <li>Evaluate, design, develop and deploy software components.</li>
              <li>
                Use design practices, suggesting latest tools/technologies to
                improve agility, reliability and performance of software
                components.
              </li>
              <li>
                Must have exp. in tools &amp; technologies such as Java/J2EE,
                Swing, Spring, Hibernate, Jasper Reports,MySQL, PL/SQL, .Net,
                SOAP UI, Unix and other latest technologies.
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location: Houston, TX with required travel to client
                locations throughout the USA
              </li>
              <li className="list-group-item">Salary: $ 126,485/Year</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 1500 South Dairy Ashford Rd, Suite 355, Houston,
            TX- 77077
            <h4>
              Send resume to{" "}
              <a href="mailto:hr@celersoft.com">hr@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Sr. Systems Architect | Job ID: 20221103 | 1-Nov-2022
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Bachelor’s degree in Engineering(Any),Computer
                Science,Technology or related and 5 yrs of exp to evaluate the
                technical hardware &amp; software infrastructure of the
                environment and design applications to work optimally within
                that environment.
              </li>
              <li>
                Understand the business needs as described in use cases, user
                stories and functional descriptions and be able to translate
                them into technical/system specifications that fit within the
                target technical architecture.
              </li>
              <li>
                Provide standard solutions and capture system requirements.
              </li>
              <li>Experience with agile and waterfall methodologies.</li>
              <li>
                Experience in SQL databases, relational databases and
                cloud-based architecture with containerization, micro services,
                and API integrations.
              </li>
              <li>
                Write technical scope of work for professional services team in
                proposal system and assist in developing roadmap for the project
                by creating the environment.
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location: Houston, TX with required travel to client
                locations throughout the USA
              </li>
              <li className="list-group-item">Salary: $ 105,394/Year</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 1500 South Dairy Ashford Rd, Suite 355, Houston,
            TX- 77077
            <h4>
              Send resume to{" "}
              <a href="mailto:hr@celersoft.com">hr@celersoft.com</a>
            </h4>
          </div>
        </div>
        {/* Individual Job Card Start */}
        <div className="panel panel-default">
          <div className="panel-heading">
            QA Engineer | Job ID: 20200567 | 10-Oct-2021
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Design test cases, execute testcases, raise defects and report
                test metrics to senior management using the Test Management tool
                Zephyr for functional, API’s, UI, End-End and
                Penetration/security testing
              </li>
              <li>
                Create Test Strategy for Mobile App testing to support Multiple
                devices and multiple OS by leveraging perfecto.
              </li>
              <li>
                Involved in every step of software development process like
                Business Requirements Analysis &amp; documentation, designing
                testcases for BDD and Automating Tests to support continuous
                integration testing.
              </li>
              <li>
                Responsibilities for testing the changes done by (Idp) team
                tested the changes performed for the issues found by info sec
                security team
              </li>
              <li>
                Design, Execute and implement critical test cases in
                specifically as part of Penetration/security testing to break
                and find the vulnerability issues.
              </li>
              <li>
                Identify security flaws in the applications in the higher and
                lower environments (QA, Stage, Pre-prod and Prod) to avoid the
                breach before releases.
              </li>
              <li>
                Experience in leading the QA Team and coordinating with the
                security team Triaging issues and finding solutions with highest
                standard quality on time.
              </li>
              <li>
                This is a full time 40 hours per week for the duration of the
                contract.
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location: 8401 Memorial Lane, Unit 7459 Plano, TX 75024
              </li>
              <li className="list-group-item">Salary: $84,512/Year</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:jobs@celersoft.com">jobs@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Sr. Software Developer | Job ID ITG-20210524-A | 01-June-2021
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            Plan, design, develop, test, integrate, customize and implement
            computer software applications and components in the complex cloud
            computing environment on Dell Boomi platform. Gather business
            requirements to integrate and configure software modules. Migrate
            and transform data between databases. Travel and/or relocation to
            various unanticipated locations throughout the US may be required.
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Master's degree in Computers or relevant Engineering.</li>
              <li>3+ years of relevant industry experience.</li>
              <li>Relevant industry certifications required.</li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location:Houston, TX 77099
              </li>
              <li className="list-group-item">Salary: $ 106,662 / Year </li>
              <li className="list-group-item">SOC Code: 15-1132</li>
              <li className="list-group-item">PWR #:P-100-21004-993365</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Sr. Software Developer | Job ID ITG-20210524-B | 01-June-2021
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            Plan, design, develop, test, integrate, customize and implement
            computer software applications and components in the complex cloud
            computing environment. Gather business requirements to integrate and
            configure software modules. Migrate and transform data between
            databases. Travel and/or relocation to various unanticipated
            locations throughout the US may be required.
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Bachelor's degree in Computer Science, Engineering or
                Information Technology.
              </li>
              <li>
                5 years experience in any IT related occupation is needed.
              </li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location:Houston, TX 77099
              </li>
              <li className="list-group-item">Salary: $ 123,323 / Year</li>
              <li className="list-group-item">SOC Code: 15-1132</li>
              <li className="list-group-item">PWR #:P-100-21004-993365</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            QA Automation Engineer, Houston, TX | Job ID ITG-20200924 |
            24-September-2020
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Manual and Automation Testing using Silk Test.</li>
              <li>Experience in using Test Management Tools.</li>
              <li>Experience in using JIRA for defect management.</li>
              <li>Handled daily status meetings and Defect Triage calls.</li>
              <li>Writing test cases and test plans using ZEPHYR.</li>
              <li>
                Experience in testing in DEV, UAT, Staging and Production
                environments.
              </li>
              <li>Perform Daily Deployments to UAT.</li>
            </ul>
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Bachelors degree in Computers or relevant Engineering.</li>
              <li>3+ years of relevant industry experience.</li>
              <li>Relevant industry certifications required.</li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">Work Location:Houston, TX</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Integration Developer (Dell Boomi), NC | Job ID ITG-20200426 |
            26-April-2020
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Install and configure Boomi Atom on local servers for
                Development and testing.
              </li>
              <li>
                Set-up Molecule for QA and PROD configure the server/node with
                high availability.
              </li>
              <li>
                Install any custom drivers and load certificates into Boomi
                platform to connect with on-prem systems.
              </li>
              <li>
                Build cost effective reusable components throughout the platform
                keeping in mind the licensing of Boomi.
              </li>
              <li>
                Set-up custom Database connectors to use Windows authentication
                instead of SQL authentication for higher security.
              </li>
              <li>
                Define the logging mechanism for error and success logs that
                could be used across multiple integrations.
              </li>
              <li>
                Prepare documentation for best practices in Boomi and provide
                necessary insight into pre and post deployment steps.
              </li>
              <li>
                Document all the package deployments and save them to Office365.
              </li>
            </ul>
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Bachelors degree in Computers or relevant Engineering.</li>
              <li>3+ years of relevant industry experience.</li>
              <li>Relavant industry certifications required.</li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">Work Location:NC</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Sr. Software Engineer | Job ID ITG-20200422 | 22-April-2020
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Plan, design, develop, test, enhance, customize and co-ordinate
                activities to implement software module components in complex
                computer environments.
              </li>
              <li>
                Analyze user requirements, prepare technical design and
                functional specifications, and develop software solutions using
                latest tools and technologies.
              </li>
              <li>
                Travel and/or relocation to various unanticipated locations
                throughout US may be required.
              </li>
            </ul>
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Bachelors degree in Computer Science, CIS or Engineering.</li>
              <li>
                5+ years experience as a Software Developer or relevant IT
                experience.
              </li>
              <li>Relavant industry certifications required.</li>
            </ul>
            <ul className="list-group">
              <li className="list-group-item">
                Work Location:Houston, TX 77099
              </li>
              <li className="list-group-item">Salary: $128,482/Year</li>
              <li className="list-group-item">SOC Code: 15-1132</li>
              <li className="list-group-item">PWR #:P-100-20013-249833</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Software Engineers | Job ID ITG-201902-006 | 16-Feb-2019
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Plan, Design, Develop &amp; document computer software programs,
                apply knowledge of programming techniques &amp; computer
                systems.
              </li>
              <li>
                Review &amp; alter programs to increase operating
                efficiency/adapt to new requirements utilizing Cloud software
                platforms: Salesforce, Oracle Fusion, Microsoft Azure, Amazon
                Webservices, and RDBMS, Web Service APIs, BI and Reporting
                frameworks, SQL SERVER, XML Publisher, Query tools, Oracle, DB2,
                Windows NT, Unix/Linux &amp; Object oriented programming
                paradigms.
              </li>
              <li>Willing to travel and work in unanticipated locations.</li>
            </ul>
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Masters degree in Computer Science or Engineering.</li>
              <li>5+ years of relevant IT experience.</li>
              <li>Relavant industry certifications preferred.</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
        <div className="panel panel-default">
          <div className="panel-heading">
            Computer Programmer | Job ID ITG-201902-007 | 20-Feb-2019
          </div>
          <div className="panel-body">
            <h4>Key Responsibilities</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>
                Build, Create New Environments for PeopleSoft Applications and
                deploy the code for migration.
              </li>
              <li>Create and modify UNIX scripts.</li>
              <li>
                Write computer programs to store, locate, &amp; retrieve
                specific documents, data, &amp; information.
              </li>
              <li>
                Update and maintain patches, fixes for PeopleSoft applications.
              </li>
              <li>
                Utilize PeopleTools, Data Mover, Change Assistant, Putty,
                Citrix, VMWare, Application Designer, Application Engine, SQR,
                Crystal Reports, SQL Server, Oracle, DB2, Unix, Linux, HP-UX,
                AIX Servers, SQLPLUS, Toad, SQL Developer, Tuxedo, Weblogic.
              </li>
              <li>Will work in unanticipated locations with US.</li>
            </ul>
            <h4>Qualifications</h4>
            <ul style={{ marginLeft: 20, marginBottom: 10 }}>
              <li>Bachelors degree in Computer Science, CIS or Engineering.</li>
              <li>2+ years of relevant IT experience.</li>
              <li>Relavant industry certifications preferred.</li>
            </ul>
            <h4>
              Apply with 2 copies of resume mentioning the position applied to:
            </h4>
            HR, Celersoft Inc. 25807 Westheimer Parkway, Suite 450, Katy,
            Texas-77494
            <h4>
              Send resume to{" "}
              <a href="mailto:careers@celersoft.com">careers@celersoft.com</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
</>
);
};

export default Careers