
import React from "react";
import Navbar from '../components/Navbar';
function Home() {
    return (
        <>
        <div className="container-fluid topbar px-20 d-none d-lg-block">
          <div className="container-fluid px-20">
            <div className="row gx-0 align-items-center" style={{ height: 45 }}>
              <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                <div className="d-flex flex-wrap">
                  <a href="/" className="text-light me-4">
                    <i className="fas fa-phone-alt text-primary me-2" />
                    (+1) 832-225-8898
                  </a>
                  <a href="/" className="text-light me-0">
                    <i className="fas fa-envelope text-primary me-2" />
                    info@celersoft.com
                  </a>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-end">
                <div className="d-flex align-items-center justify-content-end">
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-facebook-f text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-twitter text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-3"
                  >
                    <i className="fab fa-instagram text-white" />
                  </a>
                  <a
                    href="/"
                    className="btn btn-primary btn-square rounded-circle nav-fill me-0"
                  >
                    <i className="fab fa-linkedin-in text-white" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container-fluid sticky-top px-0">
          <div
            className="position-absolute bg-dark"
            style={{ left: 0, top: 0, width: "100%", height: "100%" }}
          ></div>
          <div className="container-fluid px-0">
            <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
              <a href="/" className="navbar-brand p-0">
                <img src="assets/img/logofooter.png" />

              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span className="fa fa-bars" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                <Navbar />                 
                </div>
                <div className="d-flex align-items-center flex-nowrap pt-xl-0">
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div
          className="modal fade"
          id="searchModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h4 className="modal-title mb-0" id="exampleModalLabel">
                  Search by keyword
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input
                    type="search"
                    className="form-control p-3"
                    placeholder="keywords"
                    aria-describedby="search-icon-1"
                  />
                  <span id="search-icon-1" className="input-group-text p-3">
                    <i className="fa fa-search" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="carouselExampleCaptions" className="carousel slide" data-carousel-init data-ride="carousel"> 
  <div className="carousel-indicators">
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to={0}
      className="active"
      aria-current="true"
      aria-label="Slide 1"
    />
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to={1}
      aria-label="Slide 2"
    />
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to={2}
      aria-label="Slide 3"
    />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="assets/img/slide1.jpg" className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h3>Infrastructure Solutions</h3>
        <p>Cloud Infrastructure solutions,<br />Physical Infrastructure</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="assets/img/slide2.jpg" className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h3> Business Analytics</h3>
        <p>The various data and analytical methods help business in taking effective C-Suit decisions</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="assets/img/slide4.jpg" className="d-block w-100" alt="..." />
      <div className="carousel-caption d-none d-md-block">
        <h3>Cyber Security</h3>
        <p>Continuously assess and mitigate risk from any cyber attacks, devices, users and appliactions</p>
      </div>
    </div>
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleCaptions"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>


        <div className="container-fluid about bg-light py-3">
          <div className="container py-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 col-xl-5 wow fadeInLeft" data-wow-delay="0.1s">
                <div className="about-img">
                  <img
                    src="assets/img/about.png"
                    className="img-fluid w-100 rounded-top bg-white"
                    alt="Image"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-7 wow fadeInRight"
                data-wow-delay="0.3s"
              >
                <h2 className="text-primary">CELERSOFT</h2>
                <h1 className="display-5 mb-4">DELIVERS IT SERVICES</h1>
                <p className="text ps-4 mb-4">
                  ...with a special focus on innovation and adopting excellent
                  delivery practices. Our offerings span business and technology
                  consulting, application services, systems integration, product
                  engineering, custom software development, maintenance, and quality
                  assurance services.
                </p>
                <div className="row g-4 justify-content-between mb-5">
                  <div className="col-lg-6 col-xl-5">
                    <p className="text-dark">
                      <i className="fas fa-check-circle text-primary me-1" /> Strategy
                      &amp; Consulting
                    </p>
                    <p className="text-dark mb-0">
                      <i className="fas fa-check-circle text-primary me-1" /> Business
                      Process
                    </p>
                  </div>
                  <div className="col-lg-6 col-xl-7">
                    <p className="text-dark">
                      <i className="fas fa-check-circle text-primary me-1" />{" "}
                      Marketing Rules
                    </p>
                    <p className="text-dark mb-0">
                      <i className="fas fa-check-circle text-primary me-1" />{" "}
                      Partnerships
                    </p>
                  </div>
                </div>
                <div className="row g-4 justify-content-between mb-5">
                  <div className="col-xl-5">
                    <a href="/" className="btn btn-primary rounded-pill py-3 px-5">
                      Discover More
                    </a>
                  </div>
                  <div className="col-xl-7 mb-5">
                    <div className="about-customer d-flex position-relative">
                      <img
                        src="assets/img/customer-img-1.jpg"
                        className="img-fluid btn-xl-square position-absolute"
                        style={{ left: 0, top: 0 }}
                        alt="Image"
                      />
                      <img
                        src="assets/img/customer-img-2.jpg"
                        className="img-fluid btn-xl-square position-absolute"
                        style={{ left: 45, top: 0 }}
                        alt="Image"
                      />
                      <img
                        src="assets/img/customer-img-3.jpg"
                        className="img-fluid btn-xl-square position-absolute"
                        style={{ left: 90, top: 0 }}
                        alt="Image"
                      />
                      <img
                        src="assets/img/customer-img-1.jpg"
                        className="img-fluid btn-xl-square position-absolute"
                        style={{ left: 135, top: 0 }}
                        alt="Image"
                      />
                      <div
                        className="position-absolute text-dark"
                        style={{ left: 220, top: 10 }}
                      >
                        <p className="mb-0">5m+ Trusted</p>
                        <p className="mb-0">Global Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-4 text-center align-items-center justify-content-center">
                  <div className="col-sm-4">
                    <div className="bg-primary rounded p-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="counter-value fs-1 fw-bold text-dark"
                          data-toggle="counter-up"
                        >
                          200
                        </span>
                        <h4
                          className="text-dark fs-1 mb-0"
                          style={{ fontWeight: 600, fontSize: 25 }}
                        >
                          +
                        </h4>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="text-white mb-0">Projects Completed</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="bg-dark rounded p-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="counter-value fs-1 fw-bold text-white"
                          data-toggle="counter-up"
                        >
                          21
                        </span>
                        <h4
                          className="text-white fs-1 mb-0"
                          style={{ fontWeight: 600, fontSize: 25 }}
                        >
                          +
                        </h4>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="mb-0">Clients</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="bg-primary rounded p-4">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="counter-value fs-1 fw-bold text-dark"
                          data-toggle="counter-up"
                        >
                          57
                        </span>
                        <h4
                          className="text-dark fs-1 mb-0"
                          style={{ fontWeight: 600, fontSize: 25 }}
                        >
                          +
                        </h4>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-center">
                        <p className="text-white mb-0">Team Members</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid service py-3">
          <div className="container py-5">
            <div
              className="text-center mx-auto pb-5 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: 800 }}
            >
              <h2 className="text-primary">SERVICES WE PROVIDE:</h2>
            </div>
            <div className="row g-4 justify-content-center text-center">
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item bg-light rounded">
                  <div className="service-img">
                    <img
                      src="assets/img/service-1.jpg"
                      className="img-fluid w-100 rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="service-content text-center p-4">
                    <div className="service-content-inner">
                      <a href="/infrastructure" className="h4 mb-4 d-inline-flex text-start">
                        <i className="fas fa-donate fa-2x me-2" /> Infrastructure                         Solutions
                      </a>
                      <p className="mb-4">
                        Empower your business with robust, scalable, and secure
                        infrastructure solutions designed to support today’s digital...
                      </p>
                      <a className="btn btn-light rounded-pill py-2 px-4" href="/infrastructure">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded">
                  <div className="service-img">
                    <img
                      src="assets/img/service-2.jpg"
                      className="img-fluid w-100 rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="service-content text-center p-4">
                    <div className="service-content-inner">
                      <a href="/business" className="h4 mb-4 d-inline-flex text-start">
                        <i className="fas fa-donate fa-2x me-2" /> Business Analytics
                      </a>
                      <p className="mb-4">
                        Unlock powerful insights and drive smarter decision-making
                        with our Business Analytics solutions. We specialize ...
                      </p>
                      <a className="btn btn-light rounded-pill py-2 px-4" href="/business">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="service-item bg-light rounded">
                  <div className="service-img">
                    <img
                      src="assets/img/service-4.jpg"
                      className="img-fluid w-100 rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="service-content text-center p-4">
                    <div className="service-content-inner">
                      <a href="/erp" className="h4 mb-4 d-inline-flex text-start">
                        <i className="fas fa-donate fa-2x me-2" /> ERP Solutions
                      </a>
                      <p className="mb-4">
                        Streamline, innovate, and scale your business operations with
                        our expert SAP services. Our team specializes...
                      </p>
                      <a className="btn btn-light rounded-pill py-2 px-4" href="/erp">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="service-item bg-light rounded">
                  <div className="service-img">
                    <img
                      src="assets/img/service-3.jpg"
                      className="img-fluid w-100 rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="service-content text-center p-4">
                    <div className="service-content-inner">
                      <a href="/cyber" className="h4 mb-4 d-inline-flex text-start">
                        <i className="fas fa-donate fa-2x me-2" /> Cyber Security
                      </a>
                      <p className="mb-4">
                      In today’s digital landscape, robust cybersecurity is essential for safeguarding sensitive data and ensuring business...
                      </p>
                      <a className="btn btn-light rounded-pill py-2 px-4" href="/cyber">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* <div className="col-12">
                <a
                  className="btn btn-primary rounded-pill py-3 px-5 wow fadeInUp"
                  data-wow-delay="0.1s"
                  href="/"
                >
                  Services More
                </a>
              </div> */}
            </div>
            <div className="row g-4 justify-content-center text-center">
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item bg-light rounded">
                  <div className="service-img">
                    <img
                      src="assets/img/service-1.jpg"
                      className="img-fluid w-100 rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="service-content text-center p-4">
                    <div className="service-content-inner">
                      <a href="/micro" className="h4 mb-4 d-inline-flex text-start">
                        <i className="fas fa-donate fa-2x me-2" /> Micro Services
                      </a>
                      <p className="mb-4">
                      Microservices architecture is transforming how applications are built, scaled, and managed. By breaking down...
                      </p>
                      <a className="btn btn-light rounded-pill py-2 px-4" href="/micro">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="service-item bg-light rounded">
                  <div className="service-img">
                    <img
                      src="assets/img/service-2.jpg"
                      className="img-fluid w-100 rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="service-content text-center p-4">
                    <div className="service-content-inner">
                      <a href="/It" className="h4 mb-4 d-inline-flex text-start">
                        <i className="fas fa-donate fa-2x me-2" /> IT<br />Staffing
                      </a>
                      <p className="mb-4">
                      Our IT recruitment services connect you with top talent, handpicked to drive your business forward. In today’s fast-evolving tech...
                      </p>
                      <a className="btn btn-light rounded-pill py-2 px-4" href="/It">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>

        <div className="container-fluid project">
          <div className="container">
            <div
              className="text-center mx-auto pb-5 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: 800 }}
            >
              <h4 className="text-primary">Our Projects</h4>
              <h1 className="display-4">Explore Our Portfolio</h1>
            </div>
            <div
              className="project-carousel owl-carousel wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="project-item h-100 wow fadeInUp" data-wow-delay="0.1s">
                <div className="project-img">
                  <img
                    src="assets/img/projects-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt="Image"
                  />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className="fas fa-chart-line fa-4x text-primary" />
                    </div>
                    <p className="text-dark fs-5 mb-3">Data flow management</p>
                    <a href="/" className="h4">
                      Optimize resource utilization by moving data between data
                      centers or between on-premises infrastructure and cloud
                      infrastructure...
                    </a>
                    <div className="pt-4">
                      <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-item h-100 wow fadeInUp" data-wow-delay="0.3s">
                <div className="project-img">
                  <img
                    src="assets/img/projects-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt="Image"
                  />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className="fas fa-signal fa-4x text-primary" />
                    </div>
                    <p className="text-dark fs-5 mb-3">Cloud migration</p>
                    <a href="/" className="h4">
                      Because the continuous replication occurs in the background,
                      with no performance disruption, the actual cutover usually takes
                      just a few...
                    </a>
                    <div className="pt-4">
                      <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-item h-100">
                <div className="project-img">
                  <img
                    src="assets/img/projects-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt="Image"
                  />
                </div>
                <div className="project-content bg-light rounded p-4">
                  <div className="project-content-inner">
                    <div className="project-icon mb-3">
                      <i className="fas fa-signal fa-4x text-primary" />
                    </div>
                    <p className="text-dark fs-5 mb-3">
                      Application Performance Management
                    </p>
                    <a href="/" className="h4">
                      The Six Pillars of Modern Dynamic APM
                    </a>
                    <div className="pt-4">
                      <a className="btn btn-light rounded-pill py-3 px-5" href="/">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="container-fluid testimonial bg-light py-3">
          <div className="container py-5">
            <div className="row g-4 align-items-center">
              <div className="col-xl-4 wow fadeInLeft" data-wow-delay="0.1s">
                <div className="h-100 rounded">
                  <h4 className="text-primary">Testimonials </h4>
                  <h1 className="display-4 mb-4">Clients are Talking</h1>
                  <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                    atque soluta unde itaque. Consequatur quam odit blanditiis harum
                    veritatis porro.
                  </p>
                  <a
                    className="btn btn-primary rounded-pill text-white py-3 px-5"
                    href="/"
                  >
                    Read All Reviews <i className="fas fa-arrow-right ms-2" />
                  </a>
                </div>
              </div>
              <div className="col-xl-8">
                <div
                  className="testimonial-carousel owl-carousel wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="testimonial-item bg-white rounded p-4 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="d-flex">
                      <div>
                        <i className="fas fa-quote-left fa-3x text-dark me-3" />
                      </div>
                      <p className="mt-4">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        Magnam eos impedit eveniet dolorem culpa ullam incidunt vero
                        quo recusandae nemo? Molestiae doloribus iure,
                      </p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="my-auto text-end">
                        <h5>Person Name</h5>
                        <p className="mb-0">Profession</p>
                      </div>
                      <div className="bg-white rounded-circle ms-3">
                        <img
                          src="assets/img/testimonial-1.jpg"
                          className="rounded-circle p-2"
                          style={{
                            width: 80,
                            height: 80,
                            border: "1px solid",
                            borderColor: "var(--bs-primary)"
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="testimonial-item bg-white rounded p-4 wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <div className="d-flex">
                      <div>
                        <i className="fas fa-quote-left fa-3x text-dark me-3" />
                      </div>
                      <p className="mt-4">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        Magnam eos impedit eveniet dolorem culpa ullam incidunt vero
                        quo recusandae nemo? Molestiae doloribus iure,
                      </p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="my-auto text-end">
                        <h5>Person Name</h5>
                        <p className="mb-0">Profession</p>
                      </div>
                      <div className="bg-white rounded-circle ms-3">
                        <img
                          src="assets/img/testimonial-2.jpg"
                          className="rounded-circle p-2"
                          style={{
                            width: 80,
                            height: 80,
                            border: "1px solid",
                            borderColor: "var(--bs-primary)"
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="testimonial-item bg-white rounded p-4 wow fadeInUp"
                    data-wow-delay="0.7s"
                  >
                    <div className="d-flex">
                      <div>
                        <i className="fas fa-quote-left fa-3x text-dark me-3" />
                      </div>
                      <p className="mt-4">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                        Magnam eos impedit eveniet dolorem culpa ullam incidunt vero
                        quo recusandae nemo? Molestiae doloribus iure,
                      </p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="my-auto text-end">
                        <h5>Person Name</h5>
                        <p className="mb-0">Profession</p>
                      </div>
                      <div className="bg-white rounded-circle ms-3">
                        <img
                          src="assets/img/testimonial-3.jpg"
                          className="rounded-circle p-2"
                          style={{
                            width: 80,
                            height: 80,
                            border: "1px solid",
                            borderColor: "var(--bs-primary)"
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="container-fluid footer py-3 wow fadeIn" data-wow-delay="0.2s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <div className="footer-item d-flex flex-column">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Home
                    </a>
                    <a href="/management">
                      <i className="fas fa-angle-right me-2" /> About Us
                    </a>                   
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Careers
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">Contact Info</h4>
                <div className="single_address">
                  <p>
                    <span style={{ fontSize: 20 }}>
                      <em>
                        <strong>USA</strong>
                      </em>
                    </span>
                  </p>
                  <p>
                    1500 S Dairy Ashford Rd
                    <br />
                    Ste 355 Houston
                    <br />
                    TX 77077
                  </p>
                  <hr />
                  <p>
                    13501 Katy FWY,
                    <br />
                    Suite: 1540, Houston,
                    <br />
                    Texas- 77079
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">&nbsp;</h4>
                <div className="single_address">
                  <p>
                    <em>
                      <span style={{ fontSize: 20 }}>
                        <strong>CANADA</strong>
                      </span>
                    </em>
                  </p>
                  <p>
                    998 Loft Court,
                    <br />
                    London, Ontario
                    <br />
                    N6G0J9
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <h4 className="text-white mb-4">&nbsp;</h4>
                  <a href="">
                    <i className="fas fa-envelope me-2" /> info@celersoft.com
                  </a>
                  <a href="">
                    <i className="fas fa-phone me-2" /> (+1) 832-225-8898
                  </a>
                  <hr />
                  <div className="d-flex align-items-center">
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-instagram" />
                    </a>
                    <a className="btn btn-light btn-md-square me-0" href="">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid copyright py-3">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 text-center text-md-start mb-md-0">
                <span className="text-body">
                  <i className="fas fa-copyright text-light me-1" />
                  2024 Celersoft. All rights reserved.
                </span>
              </div>
              <div className="col-md-6 text-center text-md-end text-body">
              <a
                    className="border-bottom text-primary"
                    href="https://htmlcodex.com"
                >
                    HTML Codex
                </a>{" "}
                Distributed By{" "}
                <a
                    className="border-bottom text-primary"
                    href="https://themewagon.com"
                >
                    ThemeWagon
                </a>


              </div>
            </div>
          </div>
        </div>

        <a href="/" className="btn btn-primary btn-lg-square back-to-top">
          <i className="fa fa-arrow-up" />
        </a>

      </>
      
      
    );
};

export default Home