import Navbar from '../components/Navbar';
function Services() {
    return (
      <>
      <div className="container-fluid topbar px-20 d-none d-lg-block">
      <div className="container-fluid px-20">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                (+1) 832-225-8898
              </a>
              <a href="/" className="text-light me-0">
                <i className="fas fa-envelope text-primary me-2" />
                info@celersoft.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-facebook-f text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-twitter text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-instagram text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-0"
              >
                <i className="fab fa-linkedin-in text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid sticky-top px-0">
      <div
        className="position-absolute bg-dark"
        style={{ left: 0, top: 0, width: "100%", height: "100%" }}
      ></div>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
          <a href="/" className="navbar-brand p-0">
            <img src="assets/img/logofooter.png" />

          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Navbar />
            </div>
            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
            </div>
          </div>
        </nav>
      </div>
    </div>
<div
className="modal fade"
id="searchModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-fullscreen">
  <div className="modal-content rounded-0">
    <div className="modal-header">
      <h4 className="modal-title mb-0" id="exampleModalLabel">
        Search by keyword
      </h4>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body d-flex align-items-center">
      <div className="input-group w-75 mx-auto d-flex">
        <input
          type="search"
          className="form-control p-3"
          placeholder="keywords"
          aria-describedby="search-icon-1"
        />
        <span id="search-icon-1" className="input-group-text p-3">
          <i className="fa fa-search" />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
{/* Modal Search End */}
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Services
  </h4>
 
</div>
</div>
        <section className="feature section-padding" id="service">
        <div className="container">
          <div className="row text-center">
            <div
              className="section-title wow zoomIn"
              style={{ visibility: "visible", animationName: "zoomIn" }}
            >
              <h1>CELERSOFT DELIVERS IT SERVICES</h1>
              <p>
                ...with a special focus on innovation and adopting excellent delivery
                practices. Our offerings span business and technology consulting,
                application services, systems integration, product engineering, custom
                software development, maintenance, and quality assurance services.
              </p>
              <p>SERVICES WE PROVIDE:</p>
            </div>
            <div className="col-md-12 col-sm-4 col-xs-12">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInLeft"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInLeft"
                  }}
                >
                  <h4>Infrastructure Solutions</h4>
                  <p>
                    Empower your business with robust, scalable, and secure
                    infrastructure solutions designed to support...
                  </p>
                  <a href="infrastructure.php">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInDown"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInDown"
                  }}
                >
                  <h4>Business Analytics</h4>
                  <p>
                    Unlock powerful insights and drive smarter decision-making with
                    our Business Analytics solutions...
                  </p>
                  <a href="business.php">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <h4>SAP Services</h4>
                  <p>
                    Streamline, innovate, and scale your business operations with our
                    expert SAP services. Our team specializes...
                  </p>
                  <a href="sap-services.php">Read More</a>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-4 col-xs-12">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <h4>Cloud Services</h4>
                  <p>
                    Our cloud services are designed to empower businesses with
                    scalable, secure, and efficient cloud solutions...
                  </p>
                  <a href="cloud-services.php">Read More</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <h4>Micro Services</h4>
                  <p>
                    OIn today's digital landscape, seamless communication between
                    applications is essential...
                  </p>
                  <a href="micro-services.php">Read More</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <h4>SOC Services</h4>
                  <p>
                    Protect your organization from evolving cyber threats with our
                    Security Operations Center...{" "}
                  </p>
                  <a href="soc-services.php">Read More</a>
                </div>
              </div>
              {/*- END COL */}
            </div>
            {/*- END COL */}
            <div className="col-md-12 col-sm-4 col-xs-12">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "hidden",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "none"
                  }}
                >
                  <h4>Cyber Security</h4>
                  <p>
                    In today's digital landscape, robust cybersecurity is essential
                    for safeguarding sensitive data...
                  </p>
                  <a href="cyber-security.php">Read More</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "hidden",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "none"
                  }}
                >
                  <h4>Recruitment Services</h4>
                  <p>
                    Our IT recruitment services connect you with top talent,
                    handpicked to drive your business forward...
                  </p>
                  <a href="recruitment-services.php">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div
                  className="about_single wow fadeInRight"
                  data-wow-delay="0.1s"
                  data-wow-duration="1s"
                  data-wow-offset={0}
                  style={{
                    visibility: "hidden",
                    animationDuration: "1s",
                    animationDelay: "0.1s",
                    animationName: "none"
                  }}
                >
                  <h4>Technology Staffing</h4>
                  <p>
                    Finding the right talent to drive your IT projects forward is
                    essential. Our specialized technology...
                  </p>
                  <a href="technology-staffing.php">Read More</a>
                </div>
              </div>
              {/*- END COL */}
              {/*- END COL */}
            </div>
            {/*- END ROW */}
          </div>
          {/*- END CONTAINER */}
        </div>
      </section>
      </>
    );
};

export default Services