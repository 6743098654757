import Navbar from '../components/Navbar';
function Business() {
    return (
      <>
      <div className="container-fluid topbar px-20 d-none d-lg-block">
      <div className="container-fluid px-20">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                (+1) 832-225-8898
              </a>
              <a href="/" className="text-light me-0">
                <i className="fas fa-envelope text-primary me-2" />
                info@celersoft.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-facebook-f text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-twitter text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-instagram text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-0"
              >
                <i className="fab fa-linkedin-in text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid sticky-top px-0">
      <div
        className="position-absolute bg-dark"
        style={{ left: 0, top: 0, width: "100%", height: "100%" }}
      ></div>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
          <a href="/" className="navbar-brand p-0">
            <img src="assets/img/logofooter.png" />

          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Navbar />
            </div>
            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
            </div>
          </div>
        </nav>
      </div>
    </div>
<div
className="modal fade"
id="searchModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-fullscreen">
  <div className="modal-content rounded-0">
    <div className="modal-header">
      <h4 className="modal-title mb-0" id="exampleModalLabel">
        Search by keyword
      </h4>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body d-flex align-items-center">
      <div className="input-group w-75 mx-auto d-flex">
        <input
          type="search"
          className="form-control p-3"
          placeholder="keywords"
          aria-describedby="search-icon-1"
        />
        <span id="search-icon-1" className="input-group-text p-3">
          <i className="fa fa-search" />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
{/* Modal Search End */}
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Business Analytics
  </h4>
 
</div>
</div>
<div className="container">
<section className="portfolio section-padding" id="portfolio">
  <div className="row">
    <div className="section-title  wow zoomIn">
      <div className="indented">
        <h2>Business Analytics Solutions for Data-Driven Decisions</h2>
        <p>
          Unlock powerful insights and drive smarter decision-making with our{" "}
          <strong>Business Analytics</strong> solutions. We specialize in
          transforming raw data into actionable insights, enabling businesses to
          make data-driven decisions that enhance efficiency, improve customer
          experiences, and boost profitability. From data visualization to
          advanced analytics, our services empower organizations to harness the
          full potential of their data assets.
        </p>
        <h3>Our Business Analytics Service Offerings</h3>
        <ol type={1}>
          <li>
            Data Strategy and Consulting
            <ul>
              <li>
                Develop a customized data strategy aligned with your business
                goals, designed to unlock valuable insights from your data.
              </li>
              <li>
                Advisory services to identify key metrics, data sources, and
                analytics tools that maximize ROI.
              </li>
            </ul>
          </li>
          <li>
            Data Warehousing and Management
            <ul>
              <li>
                Design and implement secure, scalable data warehouses to
                centralize data from multiple sources.
              </li>
              <li>
                Data integration and quality management to ensure consistency
                and reliability across your data assets.
              </li>
            </ul>
          </li>
          <li>
            Business Intelligence (BI) and Visualization
            <ul>
              <li>
                Leverage BI platforms like Power BI, Tableau, and others for
                interactive dashboards and visualizations.
              </li>
              <li>
                Real-time analytics and reporting to help stakeholders make
                informed decisions quickly and confidently.
              </li>
            </ul>
          </li>
          <li>
            Advanced Analytics and Predictive Modeling
            <ul>
              <li>
                Apply advanced statistical techniques and machine learning
                models to identify patterns and predict future trends.
              </li>
              <li>
                Predictive analytics solutions tailored to optimize operations,
                customer engagement, and risk management.
              </li>
            </ul>
          </li>
          <li>
            Big Data Analytics
            <ul>
              <li>
                Scalable big data solutions that process large volumes of
                structured and unstructured data.
              </li>
              <li>
                Hadoop, Spark, and cloud-based big data services to uncover deep
                insights from complex datasets.
              </li>
            </ul>
          </li>
          <li>
            Data Governance and Compliance
            <ul>
              <li>
                Data governance frameworks to ensure your analytics practices
                meet regulatory and compliance standards.
              </li>
              <li>
                Data privacy and security measures to safeguard sensitive
                information and build trust.
              </li>
            </ul>
          </li>
          <li>
            Customer Analytics and Personalization
            <ul>
              <li>
                Analyze customer behavior and preferences to deliver
                personalized experiences.
              </li>
              <li>
                Segmentation, churn prediction, and customer lifetime value
                (CLV) analysis to enhance engagement and loyalty.
              </li>
            </ul>
          </li>
        </ol>
        <h3>Why Choose Our Business Analytics Services?</h3>
        <ul>
          <li>Business and Technology Consulting </li>
          <li>Customer Operations Roadmap and Strategy </li>
          <li>Package Evaluation </li>
          <li>Implementation / Rollouts</li>
          <li>Upgrades</li>
          <li>Support and Maintenance </li>
          <li>Computer Science</li>
          <li>Artificial Intelligence</li>
          <li>Machine Learning</li>
        </ul>
        <ul>
          <li>
            <strong>Experienced Data Experts</strong>: Skilled data scientists
            and analysts who bring extensive industry expertise.
          </li>
          <li>
            <strong>Tailored Solutions</strong>: Customized analytics solutions
            that align with your unique business requirements and goals.
          </li>
          <li>
            <strong>Scalable and Secure</strong>: Analytics infrastructure that
            grows with your data needs, ensuring data privacy and compliance.
          </li>
        </ul>
        <p>
          Our Business Analytics solutions empower organizations to transform
          data into a competitive advantage, making complex decisions simple and
          strategic. <a href="index.html#contact">Contact Us</a> today to
          discover how we can help your business leverage data to its fullest
          potential.
        </p>
      </div>
    </div>
  </div>
</section>
</div>
<div className="container-fluid footer py-3 wow fadeIn" data-wow-delay="0.2s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <div className="footer-item d-flex flex-column">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Home
                    </a>
                    <a href="/management">
                      <i className="fas fa-angle-right me-2" /> About Us
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Careers
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">Contact Info</h4>
                <div className="single_address">
                  <p>
                    <span style={{ fontSize: 20 }}>
                      <em>
                        <strong>USA</strong>
                      </em>
                    </span>
                  </p>
                  <p>
                    1500 S Dairy Ashford Rd
                    <br />
                    Ste 355 Houston
                    <br />
                    TX 77077
                  </p>
                  <hr />
                  <p>
                    13501 Katy FWY,
                    <br />
                    Suite: 1540, Houston,
                    <br />
                    Texas- 77079
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">&nbsp;</h4>
                <div className="single_address">
                  <p>
                    <em>
                      <span style={{ fontSize: 20 }}>
                        <strong>CANADA</strong>
                      </span>
                    </em>
                  </p>
                  <p>
                    998 Loft Court,
                    <br />
                    London, Ontario
                    <br />
                    N6G0J9
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <h4 className="text-white mb-4">&nbsp;</h4>
                  <a href="">
                    <i className="fas fa-envelope me-2" /> info@celersoft.com
                  </a>
                  <a href="">
                    <i className="fas fa-phone me-2" /> (+1) 832-225-8898
                  </a>
                  <hr />
                  <div className="d-flex align-items-center">
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-instagram" />
                    </a>
                    <a className="btn btn-light btn-md-square me-0" href="">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid copyright py-3">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 text-center text-md-start mb-md-0">
                <span className="text-body">
                  <i className="fas fa-copyright text-light me-1" />
                  2024 Celersoft. All rights reserved.
                </span>
              </div>
              <div className="col-md-6 text-center text-md-end text-body">
              <a
                    className="border-bottom text-primary"
                    href="https://htmlcodex.com"
                >
                    HTML Codex
                </a>{" "}
                Distributed By{" "}
                <a
                    className="border-bottom text-primary"
                    href="https://themewagon.com"
                >
                    ThemeWagon
                </a>


              </div>
            </div>
          </div>
        </div>

        <a href="/" className="btn btn-primary btn-lg-square back-to-top">
          <i className="fa fa-arrow-up" />
        </a>
      </>
    );
};

export default Business