import Navbar from '../components/Navbar';
function Infrastructure() {
    return (
      <>
      <div className="container-fluid topbar px-20 d-none d-lg-block">
      <div className="container-fluid px-20">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                (+1) 832-225-8898
              </a>
              <a href="/" className="text-light me-0">
                <i className="fas fa-envelope text-primary me-2" />
                info@celersoft.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-facebook-f text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-twitter text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-instagram text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-0"
              >
                <i className="fab fa-linkedin-in text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid sticky-top px-0">
      <div
        className="position-absolute bg-dark"
        style={{ left: 0, top: 0, width: "100%", height: "100%" }}
      ></div>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
          <a href="/" className="navbar-brand p-0">
            <img src="assets/img/logofooter.png" />

          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Navbar />
            </div>
            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
            </div>
          </div>
        </nav>
      </div>
    </div>
<div
className="modal fade"
id="searchModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-fullscreen">
  <div className="modal-content rounded-0">
    <div className="modal-header">
      <h4 className="modal-title mb-0" id="exampleModalLabel">
        Search by keyword
      </h4>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body d-flex align-items-center">
      <div className="input-group w-75 mx-auto d-flex">
        <input
          type="search"
          className="form-control p-3"
          placeholder="keywords"
          aria-describedby="search-icon-1"
        />
        <span id="search-icon-1" className="input-group-text p-3">
          <i className="fa fa-search" />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
{/* Modal Search End */}
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Infrastructure Solutions
  </h4>
 
</div>
</div>
<div className="container">
<section className="portfolio section-padding" id="portfolio">
  <div className="row">
    <div className="section-title  wow zoomIn">
      <div className="indented">
        <section>
          <h2>With Celersoft infrastructure solutions, organizations can:</h2>
          <ul>
            <li>Enjoy tailored solutions for complex environments</li>
            <li>Predict spending more easily and consistently</li>
            <li>
              Secure guaranteed service levels for infrastructure performance
            </li>
            <li>
              Gain greater business agility with the ability to access
              infrastructure on demand
            </li>
            <li>
              Improve operational efficiency by offloading routine maintenance
              and monitoring to CelerSoft experts
            </li>
            <li>
              Increase cost efficiency by paying only for the infrastructure
              needed at any given time
            </li>
          </ul>
          <h3>Infrastructure Solutions for Modern IT Environments</h3>
          <p>
            Empower your business with robust, scalable, and secure
            infrastructure solutions designed to support today’s digital
            demands. Our Infrastructure Solutions team specializes in building
            and optimizing IT infrastructure that aligns with your business
            goals, ensuring high performance, reliability, and seamless
            scalability. From cloud integration to on-premises data centers, our
            solutions cater to businesses across industries, equipping them with
            the necessary tools and support for a resilient IT environment.
          </p>
          <h3>Our Infrastructure Solution Offerings</h3>
          <ol type={1}>
            <li>
              IT Infrastructure Assessment and Strategy
              <ul>
                <li>
                  Comprehensive assessment of your existing infrastructure to
                  identify gaps and opportunities for optimization.
                </li>
                <li>
                  Strategic planning to align IT infrastructure with current and
                  future business objectives.
                </li>
              </ul>
            </li>
            <li>
              {" "}
              Cloud Infrastructure Services
              <ul>
                <li>
                  Design, deployment, and management of cloud infrastructure
                  (public, private, or hybrid) tailored to your business needs.
                </li>
                <li>
                  Secure and scalable cloud solutions, with ongoing support for
                  smooth migration and minimal downtime.
                </li>
              </ul>
            </li>
            <li>
              Data Center Solutions
              <ul>
                <li>
                  Design and deployment of on-premises and hybrid data centers
                  with a focus on security, energy efficiency, and operational
                  resilience.
                </li>
                <li>
                  Support for hardware management, virtualization, and
                  automation to ensure uninterrupted performance.
                </li>
              </ul>
            </li>
            <li>
              Network Infrastructure Solutions
              <ul>
                <li>
                  Comprehensive network design, setup, and optimization for
                  faster, more reliable connectivity.
                </li>
                <li>
                  Solutions for WAN, LAN, and SD-WAN to support multi-location
                  and remote access demands.
                </li>
              </ul>
            </li>
            <li>
              Storage and Backup Solutions
              <ul>
                <li>
                  Scalable storage solutions to manage data growth securely and
                  cost-effectively.
                </li>
                <li>
                  Backup and disaster recovery services to ensure data integrity
                  and continuity in the event of disruptions.
                </li>
              </ul>
            </li>
            <li>
              Virtualization and Hyper-Converged Infrastructure
              <ul>
                <li>
                  Enable greater resource efficiency with virtualization and
                  hyper-converged systems, maximizing IT assets while minimizing
                  physical footprint.
                </li>
                <li>
                  Solutions for virtual machines, storage, and networking to
                  simplify infrastructure management.
                </li>
              </ul>
            </li>
            <li>
              Infrastructure Monitoring and Management{" "}
              <ul>
                <li>
                  24/7 monitoring and support for proactive issue detection and
                  quick response.
                </li>
                <li>
                  Automated alerts, reporting, and regular maintenance to
                  optimize infrastructure performance and longevity.
                </li>
              </ul>
            </li>
          </ol>
          <h3>Why Choose Our Infrastructure Solutions?</h3>
          <ul>
            <li>Over 30+ successful engagements in last 2 years</li>
            <li>
              More than 90% of engagements involve development &amp;
              implementation
            </li>
            <li>Over 95% of work done offshore</li>
            <li>90% of all projects are completed on time </li>
            <li>Strong Alliances with Oracle, SAP, and Salesforce.com</li>
          </ul>
          <ul>
            <li>
              <strong>Industry Expertise</strong>: Decades of experience
              designing and managing complex IT environments across various
              sectors.
            </li>
            <li>
              <strong>Scalable and Secure Solutions</strong>: Infrastructure
              tailored to grow with your business needs, keeping security and
              compliance as a top priority.
            </li>
            <li>
              <strong>End-to-End Support</strong>: From planning to
              implementation and ongoing management, we provide support at every
              stage of the infrastructure lifecycle.
            </li>
          </ul>
          <p>
            Our Infrastructure Solutions are designed to help your organization
            stay agile, secure, and efficient in today’s fast-paced digital
            landscape. <a href="index.html#contact">Contact Us</a> to learn how
            we can help build a reliable IT foundation that powers your business
            growth.
          </p>
        </section>
      </div>
    </div>
  </div>
</section>
</div>

<div className="container-fluid footer py-3 wow fadeIn" data-wow-delay="0.2s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <div className="footer-item d-flex flex-column">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Home
                    </a>
                    <a href="/management">
                      <i className="fas fa-angle-right me-2" /> About Us
                    </a>                   
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Careers
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">Contact Info</h4>
                <div className="single_address">
                  <p>
                    <span style={{ fontSize: 20 }}>
                      <em>
                        <strong>USA</strong>
                      </em>
                    </span>
                  </p>
                  <p>
                    1500 S Dairy Ashford Rd
                    <br />
                    Ste 355 Houston
                    <br />
                    TX 77077
                  </p>
                  <hr />
                  <p>
                    13501 Katy FWY,
                    <br />
                    Suite: 1540, Houston,
                    <br />
                    Texas- 77079
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">&nbsp;</h4>
                <div className="single_address">
                  <p>
                    <em>
                      <span style={{ fontSize: 20 }}>
                        <strong>CANADA</strong>
                      </span>
                    </em>
                  </p>
                  <p>
                    998 Loft Court,
                    <br />
                    London, Ontario
                    <br />
                    N6G0J9
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <h4 className="text-white mb-4">&nbsp;</h4>
                  <a href="">
                    <i className="fas fa-envelope me-2" /> info@celersoft.com
                  </a>
                  <a href="">
                    <i className="fas fa-phone me-2" /> (+1) 832-225-8898
                  </a>
                  <hr />
                  <div className="d-flex align-items-center">
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-instagram" />
                    </a>
                    <a className="btn btn-light btn-md-square me-0" href="">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid copyright py-3">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 text-center text-md-start mb-md-0">
                <span className="text-body">
                  <i className="fas fa-copyright text-light me-1" />
                  2024 Celersoft. All rights reserved.
                </span>
              </div>
              <div className="col-md-6 text-center text-md-end text-body">
              <a
                    className="border-bottom text-primary"
                    href="https://htmlcodex.com"
                >
                    HTML Codex
                </a>{" "}
                Distributed By{" "}
                <a
                    className="border-bottom text-primary"
                    href="https://themewagon.com"
                >
                    ThemeWagon
                </a>


              </div>
            </div>
          </div>
        </div>

        <a href="/" className="btn btn-primary btn-lg-square back-to-top">
          <i className="fa fa-arrow-up" />
        </a>
      </>
    );
};

export default Infrastructure