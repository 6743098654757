// Filename - "./components/Navbar.js

import React from "react";
//import { Nav, NavLink, NavMenu } from "./NavbarElements";

const Navbar = () => {
    return (
        <>
            <a href="/" className="nav-item nav-link active">
                    Home
                  </a>
                  <div className="nav-item dropdown">
                    <a
                      href="/"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      About Us
                    </a>
                    <div className="dropdown-menu m-0">
                    <a href="/management" className="dropdown-item">
                        Management
                      </a>
                      {/* <a href="/" className="dropdown-item">
                        Managed Services
                      </a> */}
                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <a
                      href="/services"
                      className="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Services
                    </a>
                    <div className="dropdown-menu m-0">
                      <a href="/infrastructure" className="dropdown-item">
                        Infrastructure Solutions
                      </a>
                      <a href="/business" className="dropdown-item">
                        Business Analytics
                      </a>
                      <a href="/erp" className="dropdown-item">
                        ERP Solutions
                      </a>
                      <a href="/cyber" className="dropdown-item">
                        Cyber Security
                      </a>
                      <a href="/micro" className="dropdown-item">
                        Micro Services
                      </a>
                      <a href="/it" className="dropdown-item">
                        IT Staffing
                      </a>
                    </div>
                  </div>
                  <a href="/solutions" className="nav-item nav-link">
                    Solutions
                  </a>
                  <a href="/careers" className="nav-item nav-link">
                    Careers
                  </a>
                  <a href="/contact" className="nav-item nav-link">
                    Contact
                  </a>
        </>
    );
};

export default Navbar;