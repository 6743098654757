import Navbar from '../components/Navbar';
function Solutions() {
    return (
      <>
      <div className="container-fluid topbar px-20 d-none d-lg-block">
      <div className="container-fluid px-20">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                (+1) 832-225-8898
              </a>
              <a href="/" className="text-light me-0">
                <i className="fas fa-envelope text-primary me-2" />
                info@celersoft.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-facebook-f text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-twitter text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-instagram text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-0"
              >
                <i className="fab fa-linkedin-in text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid sticky-top px-0">
      <div
        className="position-absolute bg-dark"
        style={{ left: 0, top: 0, width: "100%", height: "100%" }}
      ></div>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
          <a href="/" className="navbar-brand p-0">
            <img src="assets/img/logofooter.png" />

          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Navbar />
            </div>
            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
            </div>
          </div>
        </nav>
      </div>
    </div>
<div
className="modal fade"
id="searchModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-fullscreen">
  <div className="modal-content rounded-0">
    <div className="modal-header">
      <h4 className="modal-title mb-0" id="exampleModalLabel">
        Search by keyword
      </h4>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body d-flex align-items-center">
      <div className="input-group w-75 mx-auto d-flex">
        <input
          type="search"
          className="form-control p-3"
          placeholder="keywords"
          aria-describedby="search-icon-1"
        />
        <span id="search-icon-1" className="input-group-text p-3">
          <i className="fa fa-search" />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
{/* Modal Search End */}
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    Solutions
  </h4>
 
</div>
</div>
        <section className="portfolio section-padding" id="portfolio">
  <div className="container-fluid">
    <div className="row">
      <div
        className="section-title text-center wow zoomIn"
        style={{ visibility: "visible", animationName: "zoomIn" }}
      >
        <h2>OUR GREAT WORKS</h2>
      </div>
      <div className="col-md-4 col-sm-4 col-xs-12 no-padding">
        <div className="grid">
          <figure className="effect-sadie">
            <img alt="" src="assets/img/portfolio/1.jpg" />
            <figcaption
              data-target="#projectModal"
              data-toggle="modal"
              href="#"
            >
              <h4>Data flow management</h4>
            </figcaption>
          </figure>
        </div>
        {/*- END GRID */}
      </div>
      {/*- END COL */}
      <div className="col-md-4 col-sm-4 col-xs-12 no-padding">
        <div className="grid">
          <figure className="effect-sadie">
            <img alt="" src="assets/img/portfolio/2.jpg" />
            <figcaption
              data-target="#projectModal1"
              data-toggle="modal"
              href="#"
            >
              <h4>Cloud migration</h4>
            </figcaption>
          </figure>
        </div>
        {/*- END GRID */}
      </div>
      {/*- END COL */}
      <div className="col-md-4 col-sm-4 col-xs-12 no-padding">
        <div className="grid">
          <figure className="effect-sadie">
            <img alt="" src="assets/img/portfolio/3.jpg" />
            <figcaption
              data-target="#projectModal2"
              data-toggle="modal"
              href="#"
            >
              <h4>Application Performance Management</h4>
            </figcaption>
          </figure>
        </div>
        {/*- END GRID */}
      </div>
      {/*- END COL */}
      {/* Overlay Modal  */}
      <div className="modal fade" id="projectModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">DATA FLOW MANAGEMENT</h4>
            </div>
            <div className="modal-body">
              <p />
              <h5>Data Movement</h5>
              Optimize resource utilization by moving data between data centers
              or between on-premises infrastructure and cloud infrastructure
              <h5>Optimize Log Collection &amp; Analysis</h5>
              Optimize log analytics solutions such as Splunk by using HDF as a
              single platform to collect and deliver multiple data sources and
              using HDP for lower cost storage options
              <h5>Feed Data to Streaming Analytics:</h5>
              Accelerate big data ROI by streaming data into analytics systems
              such as Apache Storm or Apache Spark Streaming
              <h5>Ingest Logs for Cyber Security:</h5>
              Integrated and secure log collection for real-time data analytics
              and threat detection
              <h5>Capture IoT Data</h5>
              Transport disparate and often remote IoT data in real time,
              despite any limitations in device footprint, power or
              connectivity—avoiding data loss
              <p>
                <a href="assets/img/portfolio/image015.jpg" target="_blank">
                  <img
                    alt=""
                    className="img-responsive"
                    src="assets/img/portfolio/image015.png"
                  />{" "}
                </a>
              </p>
              <a href="assets/img/portfolio/image015.jpg" target="_blank">
                {" "}
              </a>
              <p>
                <a href="assets/img/portfolio/image016.jpg" target="_blank">
                  <img
                    alt=""
                    className="img-responsive"
                    src="assets/img/portfolio/image016.png"
                  />
                </a>
              </p>
              <a href="assets/img/portfolio/image016.jpg" target="_blank">
                {" "}
              </a>
              <p>
                <a href="assets/img/portfolio/image017.jpg" target="_blank">
                  <img
                    alt=""
                    className="img-responsive"
                    src="assets/img/portfolio/image017.jpg"
                  />{" "}
                </a>
              </p>
            </div>
            <p />
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
      <div className="modal fade" id="projectModal1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">CLOUD MIGRATION</h4>
            </div>
            <div className="modal-body">
              <p>
                <img
                  height={75}
                  src="assets/img/portfolio/Cloud migration/1.jpg"
                  width={75}
                />
              </p>
              <h5>Quick Cutovers</h5>
              Because the continuous replication occurs in the background, with
              no performance disruption, the actual cutover usually takes just a
              few minutes and can be planned in advance so as not to impact your
              business.
              <p />
              <p>
                <img
                  height={75}
                  src="assets/img/portfolio/Cloud migration/2.jpg"
                  width={75}
                />
              </p>
              <h5>Non-Disruptive</h5>
              CloudEndure operates in the background, without requiring reboot
              or impacting your system performance. Our Continuous Data
              Protection (CDP) engine works in memory — neither taking snapshots
              nor writing any data to disks — which means near-zero impact on
              performance.
              <p />
              <p>
                <img
                  height={110}
                  src="assets/img/portfolio/Cloud migration/3.jpg"
                  width={148}
                />
              </p>
              <h5>Any to Any</h5>
              Migrate all your physical, virtual, hybrid, and cloud-based
              workloads with one automated solution. CloudEndure provides
              equally efficient replication solutions for any source
              infrastructure and any target infrastructure, including physical
              or colocated data centers, VMware, and all major clouds, such as
              AWS, Microsoft Azure, Google Cloud Platform, and OpenStack. If
              needed, it is just as easy to use CloudEndure to migrate back from
              the cloud to on-premise or other cloud targets.
              <p />
              <p>
                <img
                  height={110}
                  src="assets/img/portfolio/Cloud migration/4.jpg"
                  width={248}
                />
              </p>
              <h5>Mass Migrations</h5>
              Designed for large-scale migrations, CloudEndure enables the
              replication of data from thousands of machines simultaneously
              without any performance impact. After initial replication, you can
              spin up all your machines or designated machine groups in parallel
              as well as execute any post-migration scripts at scale as
              necessary. Moreover, you can manage the entire migration lifecycle
              and verify your readiness for cutover directly from the
              CloudEndure Console.
              <p />
              <p>
                <img
                  height={75}
                  src="assets/img/portfolio/Cloud migration/5.jpg"
                  width={75}
                />
              </p>
              <h5>Enterprise-Grade Security</h5>
              CloudEndure follows the highest security standards and is ISO
              27001 compliant. We provide in-transit data encryption using AES
              256-bit and support at-rest data encryption in your target site.
              Replication traffic is transmitted directly from your source
              machines to your target site, and can be restricted to your
              private networks for better speed, lower costs, and tighter
              control.
              <p />
              <p>
                <img
                  height={75}
                  src="assets/img/portfolio/Cloud migration/6.jpg"
                  width={75}
                />
              </p>
              <h5>Independence &amp; Control</h5>
              CloudEndure gives you the control to test your migration and feel
              at ease before cutting over by just clicking a button. We also
              provide you with the ability to move easily and quickly to any
              target infrastructure of your choice, and even replicate the data
              back to your on-premise data center, which means the elimination
              of vendor lock-in.
              <p />
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
      <div className="modal fade" id="projectModal2">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">Meet Instana</h4>
            </div>
            <div className="modal-body">
              <p>
                <img
                  height={170}
                  src="assets/img/portfolio/stan_front.png"
                  width={145}
                />
              </p>
              <p />
              <ul>
                <li>
                  <a
                    href="Instana/E-Book_ The Six Pillars of Modern Dynamic APM.pdf"
                    target="_blank"
                    title="The Six Pillars of Modern Dynamic APM"
                  >
                    The Six Pillars of Modern Dynamic APM
                  </a>
                  <p />
                  <p />
                </li>
                <li>
                  <a
                    href="Instana/E-Book_ Application Performance-Management in a Containerized World.pdf"
                    target="_blank"
                    title="Application Performance Management"
                  >
                    APM in containerized World
                  </a>
                  <p />
                  <p />
                </li>
                <li>
                  <a
                    href="Instana/Ebook_ Service Quality Management in the Microservices Age.pdf"
                    target="_blank"
                    title="Service Quality Management"
                  >
                    Service Quality Management
                  </a>
                  <p />
                  <p />
                </li>
                <li>
                  <a
                    href="Instana/ClearScore_Instana_Success_Story.pdf"
                    target="_blank"
                    title="Instana Success Story"
                  >
                    Instana Success Story
                  </a>
                  <p />
                </li>
              </ul>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </div>
    {/*- END ROW */}
  </div>
  {/*- END CONTAINER-FLUID */}
</section>
</>
    );
};

export default Solutions