import Navbar from '../components/Navbar';
function Erp() {
    return (
      <>
      <div className="container-fluid topbar px-20 d-none d-lg-block">
      <div className="container-fluid px-20">
        <div className="row gx-0 align-items-center" style={{ height: 45 }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="/" className="text-light me-4">
                <i className="fas fa-phone-alt text-primary me-2" />
                (+1) 832-225-8898
              </a>
              <a href="/" className="text-light me-0">
                <i className="fas fa-envelope text-primary me-2" />
                info@celersoft.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-facebook-f text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-twitter text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-3"
              >
                <i className="fab fa-instagram text-white" />
              </a>
              <a
                href="/"
                className="btn btn-primary btn-square rounded-circle nav-fill me-0"
              >
                <i className="fab fa-linkedin-in text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid sticky-top px-0">
      <div
        className="position-absolute bg-dark"
        style={{ left: 0, top: 0, width: "100%", height: "100%" }}
      ></div>
      <div className="container-fluid px-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-1 px-4">
          <a href="/" className="navbar-brand p-0">
            <img src="assets/img/logofooter.png" />

          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Navbar />
            </div>
            <div className="d-flex align-items-center flex-nowrap pt-xl-0">
            </div>
          </div>
        </nav>
      </div>
    </div>
<div
className="modal fade"
id="searchModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-fullscreen">
  <div className="modal-content rounded-0">
    <div className="modal-header">
      <h4 className="modal-title mb-0" id="exampleModalLabel">
        Search by keyword
      </h4>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div className="modal-body d-flex align-items-center">
      <div className="input-group w-75 mx-auto d-flex">
        <input
          type="search"
          className="form-control p-3"
          placeholder="keywords"
          aria-describedby="search-icon-1"
        />
        <span id="search-icon-1" className="input-group-text p-3">
          <i className="fa fa-search" />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
{/* Modal Search End */}
{/* Header Start */}
<div className="container-fluid bg-breadcrumb">
<div className="bg-breadcrumb-single" />
<div className="container text-center py-5" style={{ maxWidth: 900 }}>
  <h4
    className="text-white display-4 mb-4 wow fadeInDown"
    data-wow-delay="0.1s"
  >
    ERP Solutions
  </h4>
 
</div>
</div>

  {/* START PORTFOLIO */}
  <div className="container pageiderp">
  <section className="portfolio section-padding" id="portfolio">
    <div className="row">
      <div className="section-title  wow zoomIn">
        <div className="indented">
          <section>
            <h2>SAP Services for the IT Industry</h2>
            <p>
              Streamline, innovate, and scale your business operations with our
              expert SAP services. Our team specializes in leveraging SAP’s
              powerful suite of solutions to drive digital transformation and
              optimize end-to-end processes tailored to the IT sector’s dynamic
              needs. We focus on helping organizations gain operational
              efficiency, improve data accuracy, and reduce costs.
            </p>
            <h3>Our SAP Service Offerings</h3>
            <ol>
              <li>
                SAP Consulting and Strategy Development
                <ul>
                  <li>
                    Assess and design SAP solutions that align with your unique
                    business goals.
                  </li>
                  <li>
                    Develop strategies for SAP integration, process
                    optimization, and customized SAP modules.
                  </li>
                </ul>
              </li>
              <li>
                SAP Implementation and Customization
                <ul>
                  <li>
                    End-to-end SAP implementation services tailored for rapid
                    deployment.
                  </li>
                  <li>
                    System configuration, data migration, and customization for
                    industry-specific requirements.
                  </li>
                </ul>
              </li>
              <li>
                SAP Cloud Solutions
                <ul>
                  <li>
                    Seamless migration to SAP’s cloud ecosystem for enhanced
                    flexibility and scalability.
                  </li>
                  <li>
                    Real-time data access, advanced analytics, and streamlined
                    collaboration through cloud deployment.
                  </li>
                </ul>
              </li>
              <li>
                SAP S/4HANA Migration
                <ul>
                  <li>
                    Upgrade to SAP S/4HANA for faster data processing and
                    real-time insights.
                  </li>
                  <li>
                    Comprehensive migration planning and execution to ensure
                    minimal disruption.
                  </li>
                </ul>
              </li>
              <li>
                SAP Application Management and Support
                <ul>
                  <li>
                    Ongoing support to ensure your SAP environment operates at
                    peak performance.
                  </li>
                  <li>
                    Proactive monitoring, troubleshooting, and updates for
                    continuous improvement.
                  </li>
                </ul>
              </li>
              <li>
                SAP Training and User Enablement
                <ul>
                  <li>
                    Custom training programs to empower your team with SAP best
                    practices.
                  </li>
                  <li>
                    Ongoing user support and guidance to maximize the value of
                    SAP tools.
                  </li>
                </ul>
              </li>
            </ol>
            <h3>Why Choose Us?</h3>
            <ul>
              <li>Onsite Staffing Solutions</li>
              <li>Offshore Staffing Solutions</li>
              <li>Nearshore Staffing Solutions</li>
              <li>Project based Staffing Solutions (SOW)</li>
              <li>Tailor Made Staffing Solutions</li>
              <li>Perm. Or Temp. To Perm. Staffing Solutions.</li>
              <li>MSP Portal based Staffing and recruiting Solutions.</li>
              <li>24/7 Recruiting Engine.</li>
            </ul>
            <p>
              <strong>Industry Expertise</strong>: Years of experience working
              with SAP technologies in IT.
            </p>
            <p>
              <strong>Certified Consultants</strong>: Our SAP-certified experts
              ensure reliable and compliant implementations.
            </p>
            <p>
              <strong>Proven Results</strong>: Improved productivity, cost
              efficiency, and process optimization.
            </p>
            <p>
              Ready to unlock the full potential of SAP for your business?{" "}
              <a href="index.html#contact">Contact Us</a> today to explore
              customized SAP solutions that drive measurable results.
            </p>
          </section>
          <section>
            <h3>Case Study 1 – COMD Escravos LPG Sales</h3>
            <ul>
              <li>
                Gas and Condensate are by-products of the crude Oil and Natural
                Gas processing. Escravos Liquefied Petroleum Gas (LPG) is one of
                them COMD sells Escravos LPG on behalf of the Federal Government
                of Nigeria
              </li>
              <li>
                Escravos LPG is a mixture of different components for e.g.
                Ethane, Propane etc.
              </li>
              <li>
                Valuation (pricing) of Escravos LPG is based on the weight of
                the main product and volume (%) of the individual components
                within the main product
              </li>
              <li>
                The order to cash process deployed for COMD in 2014/15 deemed
                unfit to capture Escravos LPG Sales
              </li>
              <li>
                The solution deployed was resulting in price discrepancies with
                inability to calculate the unit price of product per MTO
              </li>
              <li>
                Standard SAP doesn’t allow the provision to calculate the
                pricing considering weight of main product and volume of
                individual products
              </li>
              <li>
                Business were capturing revenue directly in SAP FI module
                without using SD module to capture Nominations, Liftings and
                Invoices
              </li>
              <li>
                SAPCOE designed a new process to capture nominations, liftings
                and valuations/Invoices of Escravos LPG in SD module thus
                transferring revenue to FI module
              </li>
              <li>
                All concerns of business are addressed in the new solution
              </li>
              <li>
                All required business documents for e.g. Valuation, Invoice, DPR
                and Marketing clearances are developed to be generated from SAP
                system
              </li>
              <li>
                Status – User Acceptance Testing (UAT) completed and mail
                confirmations received from Business
              </li>
              <li>
                Go Live plan - Business to capture postings from June, 2020 in
                live server
              </li>
              <li>
                A new dashboard is designed to capture the volume percentages of
                the components of the main product with features to create, edit
                and save in the database (SAP system)
              </li>
              <li>
                A strong and robust pricing routine is designed to calculate the
                average price of the main product using the volume percentages,
                daily prices from publication and the pricing option chosen by
                customer
              </li>
            </ul>
            <img src="assets/img/lpg-sales.png" />
            <h3>Case Study 2 - Crude Oil Trading &amp; LPG Sales Process </h3>
            <img src="assets/img/lpg-sales2.png" />
            <p>
              OPERATIONS DEPARTMENT - Supplier-Input-Process-Output-Customer
              (SIPOC) model
            </p>
            <table className="casestudyoperations">
              <tbody>
                <tr>
                  <td>
                    <img src="assets/img/suppliers.png" />
                    <p>Suppliers</p>
                    <ul>
                      <li>Hyson/CalsonBoard of Directors</li>
                      <li>NNPC/VITOL Top Management</li>
                      <li>COMD</li>
                      <li>PPMC</li>
                      <li>NLNG</li>
                      <li>Hyson/Calson Departments</li>
                      <li>Counterparties</li>
                    </ul>
                  </td>
                  <td>
                    <img src="assets/img/inputs.png" />
                    <p>Inputs</p>
                    <ul>
                      <li>Monthly crude oil allocation under term contract</li>
                      <li>LPG Allocation from PPMC</li>
                      <li>Request for DSDP product supply</li>
                      <li>DSDP Crude Allocation</li>
                      <li>LPG volume from NLNG</li>
                      <li>LPG sourcing from Algasco</li>
                      <li>Term contract acquisition</li>
                    </ul>
                  </td>
                  <td>
                    <img src="assets/img/processes.png" />
                    <p>Processes</p>
                    <ul>
                      <li>Crude Oil trading operations</li>
                      <li>DSDP trading operations</li>
                      <li>Domestic LPG sales operations</li>
                      <li>Trading sheet preparation </li>
                      <li>Database management</li>
                      <li>Contract management </li>
                      <li>Vessel nomination</li>
                      <li>Cargo valuation</li>
                      <li>Claims management</li>
                    </ul>
                  </td>
                  <td>
                    <img src="assets/img/outputs.png" />
                    <p>Outputs</p>
                    <ul>
                      <li>Reconciliation report</li>
                      <li>Payment advise</li>
                      <li>Payment receipt</li>
                      <li>ayment confirmation</li>
                      <li>Trading margin report</li>
                      <li>Business Performance report</li>
                      <li>Credit note Utilization</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Detail work procedure - NLNG LPG DOMESTIC SALES OPERATIONS (SPA
              CONTRACT EXECUTION)
            </p>
            <table className="casestudylpg">
              <tbody>
                <tr>
                  <th>S/N</th>
                  <th>Tasks</th>
                  <th>Steps</th>
                  <th>Timeline as per SPA</th>
                  <th>Task owner</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    Receive contract start up letter from NLNG and notification
                    of quoted price factor.{" "}
                  </td>
                  <td>
                    <ul>
                      <li>
                        Hyson communicate Annual Contract Quantity (ACQ) to NLNG
                      </li>
                      <li>
                        Finance Prepare Performance Bond (PB) and Bank Guaranty
                        (BG) for submission to NLNG{" "}
                      </li>
                    </ul>
                  </td>
                  <td>Receipt of notification</td>
                  <td>
                    SUPERVISOR
                    <br />
                    LPG
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Receive LPG SPA from NLNG </td>
                  <td>
                    <ul>
                      <li>
                        Review of SPA by Commercial and Legal Departments{" "}
                      </li>
                      <li>SPA Execution and Sign-off by both parties.</li>
                    </ul>
                  </td>
                  <td>Receipt of notification</td>
                  <td>COMMERCIAL AND LEGAL DEPTS</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    Hyson confirms Terminal User Agreement (TUA) status with
                    NPSC.{" "}
                  </td>
                  <td>
                    Hyson Pays Throughput Fees to NPSC and forwards Remita
                    Receipt to NPSC.
                  </td>
                  <td>7 days prior to the arrival of the vessel</td>
                  <td>LPG SUPERVISOR AND FINANCE MANAGER</td>
                </tr>
              </tbody>
            </table>
            <img src="assets/img/casestudy-spa.png" />
            <p>
              Detail work procedure:NLNG LPG DOMESTIC SALES OPERATIONS
              (DISCHARGE AND TRUCKING)
            </p>
            <table className="casestudylpg">
              <tbody>
                <tr>
                  <th>S/N</th>
                  <th>Tasks</th>
                  <th>Steps</th>
                  <th>Timeline as per SPA</th>
                  <th>Task owner</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    LPG Off-takers appointed Agent nominates product discharge
                    window to NLNG.
                  </td>
                  <td>
                    <ul>
                      <li>
                        NLNG review discharge Laycan\Quantity and sends to HYSON
                        for confirmation{" "}
                      </li>
                      <li>
                        HYSON confirms quantity and NLNG sends revised DI to
                        HYSON
                      </li>
                    </ul>
                  </td>
                  <td>10 Days to the 2-day Window</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    LPG Off-takers appointed Agent secures berthing window from
                    Marine and Transport Det (MTD) prior to the arrival of the
                    Vessel
                  </td>
                  <td>
                    Approval of Berthing Window communicated to the Off-Takers.
                  </td>
                  <td>5 days prior to the arrival of the vessel</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Vessel arrives Lagos from NLNG, Bonny</td>
                  <td>
                    Vessel Berths at NOJ, BOP, PWA or NAVGAS PRIVATE JETTY and
                    commences discharge to NPSC, NIPCO OR NAVGAS storage
                    facilities, Apapa
                  </td>
                  <td>A day before the first day of LAYCAN/WINDOW</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Product Nomination</td>
                  <td>Sales/Trucking of LPG product to the Domestic Market</td>
                  <td>As soon as the Vessel commences discharge operation</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
              </tbody>
            </table>
            <img src="assets/img/casestudy-crude.jpg" />
            <p>
              Detail work procedure:NLNG LPG DOMESTIC SALES OPERATIONS (INVOICE
              AND PAYMENTS)
            </p>
            <table className="casestudylpg">
              <tbody>
                <tr>
                  <th>S/N</th>
                  <th>Tasks</th>
                  <th>Steps</th>
                  <th>Timeline as per SPA</th>
                  <th>Task owner</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Hyson receives LPG invoice from NLNG</td>
                  <td>
                    <ul>
                      <li>
                        NLNG review discharge Laycan\Quantity and sends to HYSON
                        for confirmation
                      </li>
                      <li>
                        HYSON confirms quantity and NLNG sends revised DI to
                        HYSON
                      </li>
                    </ul>
                  </td>
                  <td>10 Days to the 2-day Window</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    LPG Off-takers appointed Agent secures berthing window from
                    Marine and Transport Det (MTD) prior to the arrival of the
                    Vessel
                  </td>
                  <td>
                    Approval of Berthing Window communicated to the Off-Takers.
                  </td>
                  <td>A day before the first day of LAYCAN/WINDOW</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Vessel arrives Lagos from NLNG, Bonny</td>
                  <td>
                    {" "}
                    Vessel Berths at NOJ, BOP, PWA or NAVGAS PRIVATE JETTY and
                    commences discharge to NPSC, NIPCO OR NAVGAS storage
                    facilities, Apapa{" "}
                  </td>
                  <td>A day before the first day of LAYCAN/WINDOW</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Product Nomination</td>
                  <td>Sales/Trucking of LPG product to the Domestic Market</td>
                  <td>As soon as the Vessel commences discharge operation</td>
                  <td>LPG SUPERVISOR</td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>
  </section>
  </div>
<div className="container-fluid footer py-3 wow fadeIn" data-wow-delay="0.2s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <div className="footer-item d-flex flex-column">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Home
                    </a>
                    <a href="/management">
                      <i className="fas fa-angle-right me-2" /> About Us
                    </a>                    
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Careers
                    </a>
                    <a href="/">
                      <i className="fas fa-angle-right me-2" /> Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">Contact Info</h4>
                <div className="single_address">
                  <p>
                    <span style={{ fontSize: 20 }}>
                      <em>
                        <strong>USA</strong>
                      </em>
                    </span>
                  </p>
                  <p>
                    1500 S Dairy Ashford Rd
                    <br />
                    Ste 355 Houston
                    <br />
                    TX 77077
                  </p>
                  <hr />
                  <p>
                    13501 Katy FWY,
                    <br />
                    Suite: 1540, Houston,
                    <br />
                    Texas- 77079
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <h4 className="text-white mb-4">&nbsp;</h4>
                <div className="single_address">
                  <p>
                    <em>
                      <span style={{ fontSize: 20 }}>
                        <strong>CANADA</strong>
                      </span>
                    </em>
                  </p>
                  <p>
                    998 Loft Court,
                    <br />
                    London, Ontario
                    <br />
                    N6G0J9
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-3">
                <div className="footer-item d-flex flex-column">
                  <h4 className="text-white mb-4">&nbsp;</h4>
                  <a href="">
                    <i className="fas fa-envelope me-2" /> info@celersoft.com
                  </a>
                  <a href="">
                    <i className="fas fa-phone me-2" /> (+1) 832-225-8898
                  </a>
                  <hr />
                  <div className="d-flex align-items-center">
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-twitter" />
                    </a>
                    <a className="btn btn-light btn-md-square me-2" href="">
                      <i className="fab fa-instagram" />
                    </a>
                    <a className="btn btn-light btn-md-square me-0" href="">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid copyright py-3">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 text-center text-md-start mb-md-0">
                <span className="text-body">
                  <i className="fas fa-copyright text-light me-1" />
                  2024 Celersoft. All rights reserved.
                </span>
              </div>
              <div className="col-md-6 text-center text-md-end text-body">
              <a
                    className="border-bottom text-primary"
                    href="https://htmlcodex.com"
                >
                    HTML Codex
                </a>{" "}
                Distributed By{" "}
                <a
                    className="border-bottom text-primary"
                    href="https://themewagon.com"
                >
                    ThemeWagon
                </a>


              </div>
            </div>
          </div>
        </div>

        <a href="/" className="btn btn-primary btn-lg-square back-to-top">
          <i className="fa fa-arrow-up" />
        </a>
        </>
    );
};

export default Erp